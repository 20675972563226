<div class="card ">
    <div class="card-header sk-card-header d-flex justify-content-between">
        <h4>Business Profile</h4>
    </div>
    <div class="card-block">
        <div class="row">
            <div class="col-7">
                <form *ngIf="!isLoading" [formGroup]="companyInfoFormGroup" (ngSubmit)="update()" novalidate>
                    <fieldset class="mb-100">
                        <label class="font-medium">Company Name</label>
                        <input formControlName="name" id="name" class="form-control" [ngClass]="displayFieldCss('name')" />
                        <small *ngIf="isFieldValid('name', 'required')" class="invalid-feedback">Required field</small>
                    </fieldset>

                    <fieldset class="mb-100">
                        <label class="font-medium" translate="settings.account.MICROSOFT_PARTNER_ID"></label>
                        <button type="button" class="btn sk-btn-icon">
                            <i class="material-icons md-20 icon-muted" ngbPopover="{{'settings.account.BY_PROVIDING_US_YOUR_MICROSOFT_PARTNER_ID_WE_WILL___' | translate}}">help_outline</i>
                        </button>
                        <input formControlName="mpnId" id="mpnId" class="form-control" [readonly]="!isAdmin" [ngClass]="displayFieldCss('mpnId')" />
                        <small *ngIf="isFieldValid('mpnId', 'required')" class="invalid-feedback">Required field</small>
                        <small *ngIf="isFieldValid('mpnId', 'mpnInvalid')" class="invalid-feedback" translate="REGISTRATION.ERROR.MPN_INCORRECT_FORMAT"></small>
                        <small *ngIf="isFieldValid('mpnId', 'mpnNotFound')" class="invalid-feedback" translate="REGISTRATION.ERROR.MPN_NOT_FOUND"></small>
                        <small *ngIf="isFieldValid('mpnId', 'mpnInUse')" class="invalid-feedback">MPN ID is in use, please contact <a href="https://www.skykick.com/support/get-support#contact_us" target='_blank'><u>ConnectWise Support</u></a></small>
                    </fieldset>

                    <fieldset class="mb-100">
                        <label class="font-medium" translate="settings.account.EMAIL_ADDRESS_FOR_COMMUNICATIONS"></label>
                        <button type="button" class="btn sk-btn-icon">
                            <i class="material-icons md-20 icon-muted"
                                ngbPopover="{{'settings.account.WE_NEED_AN_EMAIL_ADDRESS_TO_SEND_COMMUNICATION_TO___' | translate}}">help_outline</i>
                        </button>
                        <input formControlName="partnerContactEmail" id="partnerContactEmail" type="text" class="form-control" [ngClass]="displayFieldCss('partnerContactEmail')" />
                        <small *ngIf="isFieldValid('partnerContactEmail', 'required')" class="invalid-feedback">Required field</small>
                        <small *ngIf="isFieldValid('partnerContactEmail', 'pattern')" class="invalid-feedback">Please check format</small>
                    </fieldset>

                    <!-- <fieldset class="mb-100">
                        <label class="font-medium" translate="settings.account.DEFAULT_TIME_ZONE"></label>
                        <button type="button" class="btn sk-btn-icon">
                            <i class="material-icons md-20 icon-muted" ngbPopover="{{'settings.account.THIS_WILL_BE_THE_DEFAULT_TIME_ZONE_USED_FOR_ALL_NE__' | translate}}">help_outline</i>
                        </button>
                        <select formControlName="defaultTimeZone" id="defaultTimeZone" class="form-control form-select" [ngClass]="displayFieldCss('defaultTimeZone')">
                            <option value="" [disabled]="true" translate="settings.account.SELECT_A_TIMEZONE"></option>
                            <option *ngFor="let tz of TIMEZONES" value="{{tz}}">{{tz}}</option>
                        </select>
                    </fieldset> -->

                    <fieldset class="mb-100">
                        <label class="font-medium" translate="settings.account.DEFAULT_OFFICE_365_COUNTRY"></label>
                        <button type="button" class="btn sk-btn-icon">
                            <i class="material-icons md-20 icon-muted" ngbPopover="{{'settings.account.THIS_WILL_BE_THE_DEFAULT_OFFICE_365_COUNTRY_SPECIF__' | translate}}">help_outline</i>
                        </button>
                        <select formControlName="selectedO365CountryId" id="selectedO365CountryId" class="form-control form-select" [ngClass]="displayFieldCss('selectedO365CountryId')">
                            <option value="" [disabled]="true" translate="settings.account.SELECT_COUNTRY"></option>
                            <option *ngFor="let country of supportedO365Countries$ | async" [value]="country.id">{{country.name}}</option>
                        </select>
                    </fieldset>

                    <fieldset class="mb-100">
                        <label class="font-medium" translate="settings.account.COMPANY_ADDRESS"></label>
                        <input formControlName="address" id="address" type="text" class="form-control" [ngClass]="displayFieldCss('address')" />
                        <small *ngIf="isFieldValid('address', 'required')" class="invalid-feedback">Required field</small>
                    </fieldset>

                    <fieldset class="mb-100">
                        <div class="d-flex justify-content-between align-items-center">
                            <label class="font-medium" translate="settings.account.COMPANY_ADDRESS_LINE_2"></label>
                            <span class="text-muted">Optional</span>
                        </div>
                        <input formControlName="address2" id="address2" class="form-control" [ngClass]="displayFieldCss('address2')" />
                    </fieldset>

                    <div class="row">
                        <div class="col-6">
                            <fieldset class="mb-100">
                                <div class="d-flex justify-content-between align-items-center">
                                    <label class="font-medium" translate="settings.account.CITY"></label>
                                    <span class="text-muted">Optional</span>
                                </div>
                                <input formControlName="city" id="city" class="form-control" [ngClass]="displayFieldCss('city')" />
                            </fieldset>
                        </div>
                        <div class="col-6">
                            <fieldset *ngIf="companyInfoFormGroup.get('selectedCountryId').value == usCountryCode" id="divCompanyStateRegionList" class="mb-100">
                                <label class="font-medium"><span translate="settings.account.STATE"></span></label>
                                <select formControlName="selectedStateId" id="selectedStateId" class="form-control form-select">
                                    <option value="" [disabled]="true" translate="settings.account.SELECT_A_STATE"></option>
                                    <option *ngFor="let state of states$ | async" [value]="state.id">{{state.name}}</option>
                                </select>
                            </fieldset>

                            <fieldset *ngIf="companyInfoFormGroup.get('selectedCountryId').value != usCountryCode" class="mb-100">
                                <label class="font-medium"><span translate="settings.account.STATE_PROVINCE"></span></label>
                                <input formControlName="selectedStateId" id="selectedStateId" class="form-control"  />
                            </fieldset>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <fieldset class="mb-100">
                                <div class="d-flex justify-content-between align-items-center">
                                    <label class="font-medium" translate="settings.account.POSTAL_CODE"></label>
                                    <span class="text-muted">Optional</span>
                                </div>
                                <input formControlName="postalCode" id="postalCode" class="form-control" [ngClass]="displayFieldCss('postalCode')" />
                                <small *ngIf="isFieldValid('postalCode', 'pattern')" class="invalid-feedback">Please check format</small>
                            </fieldset>
                        </div>
                        <div class="col-6">
                            <fieldset class="mb-100">
                                <label class="font-medium" translate="settings.account.COUNTRY"></label>
                                <select formControlName="selectedCountryId" id="selectedCountryId" class="form-control form-select" [ngClass]="displayFieldCss('selectedCountryId')">
                                    <option value="" [disabled]="true" translate="settings.account.SELECT_COUNTRY"></option>
                                    <option *ngFor="let country of countries$ | async" [value]="country.id">{{country.name}}</option>
                                </select>
                            </fieldset>
                        </div>
                    </div>

                    <fieldset class="mb-100">
                        <label class="font-medium" translate="settings.account.COMPANY_PHONE"></label>
                        <input formControlName="phone" id="phone" class="form-control" [ngClass]="displayFieldCss('phone')" />
                        <small *ngIf="isFieldValid('phone', 'required')" class="invalid-feedback">Required field</small>
                    </fieldset>

                    <button id="submitBtn" sk-spinner-button type="submit" [color]="'primary'" class="mr-100"
                        [isSpinning]="isBusy" [disabled]="companyInfoFormGroup.invalid || isBusy || companyInfoFormGroup.pristine">
                        {{ 'COMMON.UPDATE' | translate }}
                    </button>
                    <button id="cancelBtn" [disabled]="isBusy || companyInfoFormGroup.pristine" (click)="cancel()" type="button" class="btn btn-outline-secondary" translate="COMMON.CANCEL"></button>
                </form>
            </div>
        </div>

        <sk-text-spinner *ngIf="isLoading" message="Loading profile"></sk-text-spinner>

    </div>
</div>
