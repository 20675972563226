import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, distinctUntilChanged, first, takeUntil, tap } from 'rxjs/operators';

import { SkyKickModal, SkyKickModalOptions, SkyKickModalService, SkyKickModalWarningOptions, WarningModalComponent } from '@skykick/core';

import { AuthService } from 'src/app/auth.service';

import { EMPTY, Observable } from 'rxjs';
import { BaseComponent } from '../../shared/components/component-base/base.component';
import { AuthenticationSettingsService } from '../../users/components/members/services/authentication.settings.service';
import { AuthenticationType } from '../models/authentication-type';
import { M365ConnectionStatus } from '../models/connection-status';
import { PartnerAuthentication } from '../models/partner-authentication';
import { AuthenticationMethodResourcesService } from '../services/authentication-method-resources.service';
import { M365ModalSettings } from './modals/ms365-authentication/m365-modal-settings';
import { M365StepName } from './modals/ms365-authentication/m365-setup-steps';
import { M365ModalService } from './modals/ms365-authentication/m365modal.service';

@Component({
    selector: 'sk-authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent extends BaseComponent implements OnInit {
    AuthenticationType = AuthenticationType;
    M365ConnectionStatus = M365ConnectionStatus;
    debugEnabled = false;
    isLoading: boolean;
    authSettingsForm: UntypedFormGroup;
    isBusy: boolean;
    partnerAuthentication: PartnerAuthentication;
    groupSyncStatus: any;
    m365AuthConnectionStatus: M365ConnectionStatus | null = null;
    isUnmatchedBannerVisible = true;

    private optionsError: SkyKickModalOptions = {
        title: this.translate.instant('ERROR'),
        body: this.translate.instant('COMMON.PLEASE_TRY_AGAIN_OR_CONTACT'),
        btnLabel: this.translate.instant('COMMON.CLOSE')
    };

    constructor(
        private toastrService: ToastrService,
        private formBuilder: UntypedFormBuilder,
        private translate: TranslateService,
        private m365modalService: M365ModalService,
        private authenticationMethodResourcesService: AuthenticationMethodResourcesService,
        private skyKickModalService: SkyKickModalService,
        private authService: AuthService,
        private authSettingsService: AuthenticationSettingsService
    ) {
      super();
    }

    ngOnInit(): void {
        this.debug("ngOnInit");
        this.isUnmatchedBannerVisible = true;

        this.authSettingsForm = this.formBuilder.group({
            authenticationType: [null, [Validators.required]], // Basic Authentication or Microsoft 365 Authentication
            isMFAEnabled: [null], // checkbox for enabling multi-factor authentication
        });
        this.authSettingsForm.get('authenticationType').valueChanges.pipe(
            distinctUntilChanged(),
        ).subscribe(value => {
            this.refreshUserChoices(value);
        });

        this.formReady();
    }

    private debug(...args: any[]) {
        if (!this.debugEnabled) return;
        console.debug("AuthenticationComponent", arguments);
    }

    // Switches the user over from M365 Auth to SkyKick Auth.
    private disableM365AndSavePartnerAuthenticationSettings() {
        this.isBusy = true;
        this.savePartnerSettingsAndActualizeState(this.getAuthSettingsPayloadFromPartnerState())
            .pipe(
                takeUntil(this.destroy$),
                catchError(() => {
                    this.isBusy = false;
                    this.toastrService.error(this.translate.instant('Error Changing O365 Login for Partner'));

                    return EMPTY;
                })
            ).subscribe();
    }

    // Disable Microsoft AAD Group sync and save partner authentication settings.
    private disableM365GroupSyncAndSavePartnerAuthenticationSettings() {
        const self = this;
        self.debug("disableM365GroupSyncAndSavePartnerAuthenticationSettings");
        const payload = this.getAuthSettingsPayloadFromPartnerState();

        this.isBusy = true;

        self.debug(payload);
        this.authenticationMethodResourcesService.disableM365GroupSync().pipe(first()).subscribe({
            next: () => {
                this.savePartnerSettingsAndActualizeState(payload).subscribe({
                    error: (error) => {
                        self.debug(error);
                        this.isBusy = false;
                        this.toastrService.error(this.translate.instant('Error Changing O365 Login for Partner'));
                        // rollback partner sync if save auth settings failed
                        this.authenticationMethodResourcesService.beginM365GroupSync().subscribe({
                            error: (error) => self.debug(error)
                        });
                    }
                });
            },
            error: () => {
                this.isBusy = false;
                this.showGenericErrorModal();
            }
        });
    }

    private actualizeState(): void {
        // If we actually updated the value, show success.
        this.informUser();

        // update partner auth data.
        this.partnerAuthentication.authenticationType = this.authSettingsForm.get('authenticationType').value;
        this.partnerAuthentication.isMFAEnabled = this.authSettingsForm.get('isMFAEnabled').value;

        this.groupSyncStatus.groupsSyncEnabled = false;
        this.patchGroupSyncOnForm();
        this.authSettingsForm.markAsPristine();
    }

    private savePartnerSettingsAndActualizeState(payload): Observable<void> {
      return this.authenticationMethodResourcesService.savePartnerAuthenticationSettings(payload)
          .pipe(tap(() => {
              this.isBusy = false;
              this.actualizeState();
          }));
    }

    private informUser() {
        if (this.authSettingsForm.get('authenticationType').value !== this.partnerAuthentication.authenticationType) {
            this.toastrService.success(this.translate.instant('settings.O365.SKYKICK_AUTH_SUCCESS'));
        }
        if (this.authSettingsForm.get('isMFAEnabled').value) {
            this.toastrService.success(this.translate.instant('settings.O365.MFA_SETTING_SUCCESS'));
        }
        if (!this.authSettingsForm.get('isMFAEnabled').value && this.authSettingsForm.get('authenticationType').value === this.partnerAuthentication.authenticationType) {
            this.toastrService.success(this.translate.instant('settings.O365.MFA_SETTING_DISABLED'));
        }
    }

    private formReady() {
        this.isLoading = true;
        this.authSettingsService.fetchAuthenticationSettings()
        .subscribe(([partnerAuthentication, groupSyncStatus, m365AuthConnectionStatus ]) => {
            this.partnerAuthentication = partnerAuthentication;
            this.partnerAuthentication.oAuthFlowState = this.partnerAuthentication.oAuthFlowState ? JSON.parse(this.partnerAuthentication.oAuthFlowState) : null;
            this.m365AuthConnectionStatus = m365AuthConnectionStatus;

            // Set the form values to what they currently are.
            this.authSettingsForm.patchValue(partnerAuthentication);
            if (this.hasOngoingAuthFlow()) {
                this.debug('ongoing auth flow');
                this.groupSyncStatus = {groupsSyncEnabled: this.partnerAuthentication.oAuthFlowState.userWantedGroupSync};
            } else {
                this.debug('no ongoing auth flow');

                this.groupSyncStatus = groupSyncStatus;
            }
            this.patchGroupSyncOnForm();

            this.isLoading = false;

            // Note: This was copied from portal v1 AdminManageAccountController.init() to fix an issue where this was missing.
            // If there is #setupo365authentication at the end of the URL, it is likely we need to take the user to step 3 of the M365Auth setup process.
            const hash = window.location.hash; // e.g. #setupo365authentication
            if (hash && hash.length > 1) {
                const sectionId = hash.substring(1);
                this.locationHashFound(sectionId);
            }
        });
    }

    private refreshUserChoices(value: AuthenticationType) {
      // MFA only makes sense for Basic Authentication.
      if (value === AuthenticationType.M365Auth) {
        this.authSettingsForm.get('isMFAEnabled').setValue(false);
        this.authSettingsForm.get('isMFAEnabled').disable();
      }
      if (value === AuthenticationType.SkyKickAuth) {
        this.authSettingsForm.get('isMFAEnabled').enable();
      }
    }

    private locationHashFound(elementId: string): void {
        const self = this;
        self.debug("Jumping to section " + elementId);
        const element = document.getElementById(elementId);
        if (elementId === 'setupo365authentication') {
            // We came back from AccessManagement success page.
            // This window.setTimeout code was taken from Portal V1.
            window.setTimeout(function () {
                self.debug("scrollIntoView timeout");
                self.authSettingsForm.get('authenticationType').patchValue(AuthenticationType.M365Auth);
                element.scrollIntoView();
            }, 2000);
            window.setTimeout(function () {
                self.debug("openAuthFlowModal timeout");
                self.openAuthFlowModal();
            }, 3000);
        }
    }

    updateAuthentication(): void {
        this.debug("updateAuthentication");
        if (this.authSettingsForm.valid && !this.authSettingsForm.pristine) {
            if (this.authSettingsForm.get('authenticationType').value === AuthenticationType.SkyKickAuth) {
                if (this.authSettingsForm.get('authenticationType').value === this.partnerAuthentication.authenticationType &&
                    this.authSettingsForm.get('isMFAEnabled').value === this.partnerAuthentication.isMFAEnabled) {
                    this.authSettingsForm.markAsPristine();
                    return;
                }

                this.userChoseSkyKickAuth();
            }
            else if (this.authSettingsForm.get('authenticationType').value === AuthenticationType.M365Auth) {
                if (this.authSettingsForm.get('authenticationType').value === this.partnerAuthentication.authenticationType) {
                     this.authSettingsForm.markAsPristine();
                     return;
                }
                this.userChoseM365Auth();
            }
        }
    }

    private userChoseSkyKickAuth() {
        if (this.checkIfSkMFAWasChanged()) {
            this.isBusy = true;
            this.savePartnerSettingsAndActualizeState(this.getAuthSettingsPayloadFromPartnerState())
                .pipe(takeUntil(this.destroy$), catchError(() => {
                    this.isBusy = false;
                    return EMPTY;
                })).subscribe();
            return;
        }

        let warningModal: SkyKickModal<WarningModalComponent, void>;
        const options: SkyKickModalWarningOptions = {
            body: this.translate.instant('settings.O365.DISABLE_WARNING_HEADER'),
            title: this.translate.instant('settings.O365.DISABLE_MODAL_TITLE'),
            btnLabel: this.translate.instant('settings.O365.DISABLE_CONFIRM_BUTTON_TEXT'),
            verifyLabel: this.translate.instant('settings.O365.DISABLE_CONFIRM_TEXT'),
            alternative: {
                btnLabel: this.translate.instant('settings.account.DISTRIBUTOR_CANCEL'),
                btnCallback: () => {
                    warningModal.dismiss();
                    this.cancel();
                }
            }
        };
        warningModal = this.skyKickModalService.warning(options);
        warningModal.result.then(res => {
            if (res.wasClosed) {
                // if the partner uses GroupSync, we must first disable it,
                // or simply save the settings
                if (this.groupSyncStatus?.groupsSyncEnabled) {
                    this.disableM365GroupSyncAndSavePartnerAuthenticationSettings();
                    return;
                }

                this.disableM365AndSavePartnerAuthenticationSettings();
            }
        });
    }

    private userChoseM365Auth(): void {
        this.openAuthFlowModal();
    }

    private openAuthFlowModal(): void {
        this.debug("openAuthFlowModal");

        if (this.hasOngoingAuthFlow()) {
            this.authSettingsForm.markAsDirty();
            this.authSettingsForm.get('authenticationType').setValue(AuthenticationType.M365Auth);
        }

        const settings: M365ModalSettings = {
            partnerAuthentication: this.partnerAuthentication,
            groupsSyncEnabled: this.groupSyncStatus.groupsSyncEnabled,
            m365AuthConnectionStatus: this.m365AuthConnectionStatus,
            isM365MatchingProcess: false,
            initialStepName: M365StepName.GrantAccess,

            handlers: {
                onSuccess: (syncGroupsEnabled: boolean) => {
                    this.partnerAuthentication.authenticationType = AuthenticationType.M365Auth;
                    this.partnerAuthentication.isMFAEnabled = false;
                    if (!syncGroupsEnabled) {
                        this.authSettingsForm.markAsPristine();
                        this.authService.logout();
                    }
                },
                onGroupSyncEnabling: () => {
                    this.authSettingsForm.markAsPristine();
                    this.isUnmatchedBannerVisible = false;
                    this.clearOngoingAuthFlow();
                },
                onFailure: (rollbacked) => {
                    if (!rollbacked)
                        return;

                    this.partnerAuthentication.authenticationType = AuthenticationType.SkyKickAuth;
                    this.m365AuthConnectionStatus = null;
                    this.authSettingsForm.patchValue(this.partnerAuthentication);
                    this.authSettingsForm.markAsPristine();
                    this.removeHash();
                },
                onRedirect: () => {
                    this.authSettingsForm.markAsPristine();
                },
                onWarning: () => {
                    this.partnerAuthentication.authenticationType = AuthenticationType.M365Auth;
                    this.partnerAuthentication.isMFAEnabled = false;
                    this.authSettingsForm.markAsPristine();
                }
            }
        }

        this.m365modalService.openAndHandleResult(settings)
            .catch(() => {
                // The user hit the X or the cancel button.
                this.cancel();
            });
    }

    isReauthenticationRequired() : boolean {
        return this.partnerAuthentication
            && this.partnerAuthentication.authenticationType === AuthenticationType.M365Auth
            && this.m365AuthConnectionStatus === M365ConnectionStatus.ReauthenticationRequired
    }

    isUnmatchedBannerShoudBeVisible() {
      return !this.isReauthenticationRequired() && this.partnerAuthentication.authenticationType === AuthenticationType.M365Auth
            && !this.hasOngoingAuthFlow() && this.isUnmatchedBannerVisible;
    }

    private patchGroupSyncOnForm(): void {
        this.authSettingsForm.patchValue(this.groupSyncStatus);
    }

    private showGenericErrorModal(): void {
        this.skyKickModalService.error(this.optionsError);
    }

    hasOngoingAuthFlow(): boolean {
        let result = false;
        if (this.partnerAuthentication?.oAuthFlowState && typeof this.partnerAuthentication.oAuthFlowState !== 'string') {
            result = !!this.partnerAuthentication.oAuthFlowState.authorizationStarted;
        }
        return result;
    }

    private clearOngoingAuthFlow() {
        const payload =  JSON.stringify({
            authorizationStarted: false,
        });
        if (this.partnerAuthentication?.oAuthFlowState) {
            this.partnerAuthentication.oAuthFlowState = null;
        }
        this.authenticationMethodResourcesService.saveOAuthFlowState({State: payload}).pipe(first()).subscribe({
            next: () => {
                this.authService.logout();
            },
            error: () => {
                this.showGenericErrorModal();
            }
    });
    }

    hasSwitchedToM365(): boolean {
        return this.partnerAuthentication.authenticationType === AuthenticationType.SkyKickAuth
            && this.authSettingsForm.get('authenticationType').value === AuthenticationType.M365Auth;
    }

    isDisabled(): boolean {
        if (this.authSettingsForm.invalid || this.isBusy || this.authSettingsForm.pristine || (this.partnerAuthentication.authenticationType === AuthenticationType.M365Auth && this.m365AuthConnectionStatus === M365ConnectionStatus.ReauthenticationRequired)) {
            return true;
        }
        return false;
    }

    private checkIfSkMFAWasChanged = () => this.authSettingsForm.get('authenticationType').value === this.partnerAuthentication.authenticationType && this.authSettingsForm.get('isMFAEnabled').value !== this.partnerAuthentication.isMFAEnabled

    private getAuthSettingsPayloadFromPartnerState = () => ({
        AuthenticationType: this.authSettingsForm.get('authenticationType').value,
        IsMFAEnabled: this.authSettingsForm.get('isMFAEnabled').value,
        UsersToMap: this.partnerAuthentication.unMappedUsers
    })

    private cancel(): void {
        this.authSettingsForm.patchValue(this.partnerAuthentication);
        this.authSettingsForm.patchValue(this.groupSyncStatus);
        this.authSettingsForm.markAsPristine();
    }

    private removeHash(): void {
        history.pushState('', document.title, window.location.pathname + window.location.search);
    }
}
